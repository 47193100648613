import { PropsWithChildren } from 'react'

import { ChevronUpIcon } from '@nx/fire/assets'
import { getTranslate } from '@nx/translations'

import { StyledBackToTop, StyledBackToTopLink } from './FireBackToTop.styles'
import translate from './FireBackToTop.translate.json'

const { text } = getTranslate(translate)

export const FireBackToTop = ({
  id,
  jumpToName,
  children,
}: PropsWithChildren<FireBackToTopProps>) => (
  <StyledBackToTop>
    {children}
    <StyledBackToTopLink href={`#${id}`}>
      <ChevronUpIcon
        width="35"
        height="35"
        title={text('backToTop.title', { jumpToName })}
      />
    </StyledBackToTopLink>
  </StyledBackToTop>
)

interface FireBackToTopProps {
  id: string
  jumpToName: string
}
